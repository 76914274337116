import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import Datatable from "react-bs-datatable";
import api from "../../Axios";
// import Pdf from "react-to-pdf";
// import { Modal, Button } from 'react-bootstrap';
import NotificationAlert from "react-notification-alert";
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import CsvDownloader from 'react-csv-downloader';
import {
  //  Page, Text, View, Document,
  StyleSheet,
} from "@react-pdf/renderer";
const ref = React.createRef();
const CancelOrders = () => {
  const [pdfData, setPdfdata] = useState([]);
  const [orderData, setOrderData] = useState([
    {
      orderId: "",
    },
    {
      TransactionStatus: "",
    },
    {
      totalAmount: "",
    },
    {
      deliveryType: "",
    },
    {
      paymentStatus: "",
    },
    {
      orderStatus: "",
    },
  ]);

  //   const newData = orderData;
  //   const fileName = 'TotalOrders';
  //   const fileType =
  //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // const fileExtension = '.xlsx';
  // const exportToCSV = (apiData, fileName) => {
  //   const ws = XLSX.utils.json_to_sheet(apiData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  const notiRef = useRef();
  // const header = [
  //   { title: "ID", prop: "id", sortable: true, filterable: true },
  //   {
  //     title: "Profile Picture",
  //     prop: "profilePic",
  //     sortable: true,
  //     filterable: true,
  //   },
  //   { title: "Name", prop: "userName", sortable: true, filterable: true },
  //   { title: "Phone", prop: "phone", sortable: true, filterable: true },
  //   { title: "Gender", prop: "gender", sortable: true, filterable: true },
  //   { title: "Email", prop: "email", sortable: true, filterable: true },
  //   { title: "Status", prop: "status", sortable: true, filterable: true },
  //   { title: "Action", prop: "action", sortable: true, filterable: true },
  // ];
  const styles = StyleSheet.create({
    page: { backgroundColor: "tomato" },
    section: { color: "white", textAlign: "center", margin: 10 },
  });

  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    { title: "Order ID", prop: "orderId", sortable: true, filterable: true },
    {
      title: "Total Amount",
      prop: "totalAmount",
      sortable: true,
      filterable: true,
    },
    {
      title: "Paid Amount",
      prop: "paidAmount",
      sortable: true,
      filterable: true,
    },
    {
      title: "Payment Status",
      prop: "paymentStatus",
      sortable: true,
      filterable: true,
    },
    {
      title: "Delivery Type",
      prop: "deliveryType",
      sortable: true,
      filterable: true,
    },
    {
      title: "Order Status",
      prop: "orderStatus",
      sortable: true,
      filterable: true,
    },
    {
      title: "TimeStamp",
      prop: "txnTimeStamp",
      sortable: true,
      filterable: true,
    },
    { title: "Action", prop: "action", sortable: true, filterable: true },
  ];

  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "No Orders Found",
  };
  const csvData = [["rahul", "delhi", "accountsdept"]];
  const [data, setData] = useState([]);
  const [render, setRerender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };
    notiRef.current.notificationAlert(options);
  }

  function oderStatus(info, e) {
    console.log(info.id, e.target.value);
    api
      .post("/admin/orders/orderStatus", {
        orderId: info.orderId,
        orderStatus: e.target.value,
      })
      .then((res) => {
        console.log(res.data.data, "order datas");
        setRerender(!render);
        notify(res.data.data, "success");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    {
      key: "orderId",
      label: "order Id",
    },
    {
      key: "txnStatus",
      label: "Transaction Status",
    },
    {
      key: "txnTimeStamp",
      label: "TimeStamp",
    },
    {
      key: "totalAmount",
      label: "Total Amount",
    },
    {
      key: "deliveryType",
      label: "Delivery Type",
    },
    {
      key: "pstatus",
      label: "Payment Status",
    },
    {
      key: "order",
      label: "Order Status",
    },
  ];

  // const headers = [
  //   {
  //     label:"Name",key:"name"
  //   },
  //   {
  //     label:"Age",key:"age"
  //   },
  //   {
  //     label:"Team",key:"team"
  //   }
  // ]
  // const csvLink = {
  //   headers: headers,
  //   data: orderData,
  //   filename:"Total Orders.csv"
  // }

  useEffect(() => {
    const getOrders = () => {
      api
        .get("/admin/orders/pending")
        .then((resp) => {
          console.log(resp.data.data, "response data");
          setPdfdata(resp.data.data);
          // setOrderData({
          //   orderId: resp.data.data.orderId,
          //   TransactionStatus:resp.data.data.transactionStatus,
          //   totalAmount:resp.data.data.amount,
          //   deliveryType:resp.data.data.deliveryType,
          //   paymentStatus:resp.data.data.paymentStatus,
          //   orderStatus:resp.data.data.orderStatus
          // });

          resp.data.data.map((data, i) => {
            setOrderData([
              {
                orderId: data.orderId,
              },
              {
                TransactionStatus: data.txnStatus,
              },
              {
                totalAmount: data.totalAmount,
              },
              {
                deliveryType: data.deliveryType,
              },
              {
                paymentStatus: data.paymentStatus,
              },
              {
                orderStatus: data.orderStatus,
              },
            ]);
          });

          Array.isArray(resp.data.data)
            ? setData(
                resp.data.data.map((data, i) => ({
                  ...data,
                  id: (
                    <span style={{ textTransform: "capitalize" }}>{++i}</span>
                  ),
                  txnTimeStamp: moment(data.txnTimeStamp).format(
                    "MMM D ddd Y, h:mm:ss a"
                  ),
                  paymentStatus: (
                    <span style={{ textTransform: "capitalize" }}>
                      {data.paymentStatus}
                    </span>
                  ),
                  pstatus: data.paymentStatus,
                  order: data.orderStatus,
                  orderStatus: (
                    <select
                      style={{ textTransform: "capitalize" }}
                      onChange={(e) => oderStatus(data, e)}
                    >
                      <option>{data.orderStatus}</option>
                      <option value="pending">Pending</option>
                      <option value="accepted">Accepted</option>
                      {/* <option value="packaging">Packaging</option>
                      <option value="dispatched">Dispatched</option>
                      <option value="delivered">Delivered</option>
                      <option value="declined">Declined</option> */}
                    </select>
                  ),
                  action: (
                    <button
                      className="btn btn-secondary btn-icon bottom15 right15"
                      style={{ padding: "4px", fontSize: "13px" }}
                      onClick={() =>
                        (window.location.href =
                          "/viewcancelorders/" + data.orderId)
                      }
                    >
                      <label style={{ margin: "0px" }}>
                        View Cancelled Orders
                      </label>
                    </button>
                  ),
                }))
              )
            : setData([]);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getOrders();
  }, [render]);

  function onRowClick(data) {
    alert(`You clicked on the row ${data.realname}`);
  }
  const csvReport = {
    data: data,
    headers: headers,
    filename: "Cancelled Orders.csv",
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Total Orders";
    const headers = [
      [
        "OrderId",
        "Total Amount",
        "Payment Status",
        "DeliveryType",
        "Order Status",
        "Txn status",
        "Timestamp",
      ],
    ];
    console.log("pdfdata", pdfData);
    const data = pdfData.map((elt) => [
      elt.orderId,
      elt.totalAmount,
      elt.paymentStatus,
      elt.deliveryType,
      elt.orderStatus,
      elt.txnStatus,
      moment(elt.createdAt).format("MMM D ddd Y, h:mm:ss a"),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("CancelledOrderDetails.pdf");
  };

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              {/* <div className="float-left">
                <h1 className="title">Cancel Orders</h1>
              </div> */}
            </div>
            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Cancelled Order Lists</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-lg-12 dt-disp">
                      <Datatable
                        onRowClick={onRowClick}
                        tableHeader={header}
                        tableBody={data}
                        keyName="userTable"
                        tableClass="striped table-hover table-responsive"
                        rowsPerPage={10}
                        rowsPerPageOption={[5, 10, 15, 20, 30]}
                        initialSort={{ prop: "id", isAscending: true }}
                        //   onSort={onSortFunction}
                        labels={customLabels}
                      />

                      <CSVLink
                        class="btn  btn-icon bottom15 right15"
                        {...csvReport}
                        style={{ color: "white", backgroundColor: "#f390bc" }}
                      >
                        Export as CSV{" "}
                      </CSVLink>

                      <button
                        class="btn btn-secondary btn-icon bottom15 right15"
                        onClick={() => exportPDF()}
                        style={{ color: "white", backgroundColor: "#f390bc" }}
                      >
                        Export as PDF
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CancelOrders;

import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import TagsInput from "react-tagsinput";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import Cropper from "../Cropper";
import "./cropper.css";
import api from "../../Axios";
import Select from "react-select";
import { customStyles } from "../../assets/css/CustomStyles";
import NotificationAlert from "react-notification-alert";

const AddVariant = () => {
  const notiRef = useRef();

  const [data, setData] = useState({
    productId: "",
    productName: "",
    tax: "",
    variantName: "",
    variantImage: [],
    tags: [],
    variantColor: "#000",
    actualPrice: 0,
    discountPrice: 0,
  });

  const [variantcount, setVariantCount] = useState(1);
  const [productList, setProductList] = useState([]);
  const [render, setRender] = useState(false);

  const [dummy, setDummy] = useState(true);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: type == "danger" ? 5 : 3,
    };
    notiRef.current.notificationAlert(options);
  }

  //----------Getting product details --------
  useEffect(() => {
    api
      .get("/admin/product/getProduct/" + null)
      .then((res) => {
        if (Array.isArray(res.data.data))
          setProductList(
            res.data.data.map((data) => {
              return {
                label: data.productName,
                productId: data.id,
                tax: data.tax,
              };
            })
          );
      })
      .catch((error) => console.log("error", error));
  }, []);
  // inputHandlers
  function handleChange(e, i, fieldName) {
    console.log(data, i);
    // product default details handle
    if (fieldName === "productName")
      setData({
        ...data,
        productId: e.productId,
        productName: e.label,
        tax: e.tax,
      });
    if (fieldName === "variantName")
      setData({ ...data, variantName: e.target.value });
    if (fieldName === "tags") {
      setData({ ...data, tags: e });
    }
    if (fieldName === "image") {
      console.log(e);
      data.variantImage.push(e);
    }
    // if (fieldName === "variantColor") {
    //   setData({ ...data, variantColor: e.target.value });
    // }
    if (fieldName === "actualPrice") {
      const actualPrice = parseFloat(e.target.value); // Convert input value to number
      setData({ ...data, actualPrice }); // Update state with the number
    }
    if (fieldName === "discountPrice") {
      const discountPrice = parseFloat(e.target.value); // Convert input value to number
      setData({ ...data, discountPrice }); // Update state with the number
    }
    setDummy(!dummy);
  }
  function handSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    api
      .post("/admin/product/addVariant", data)
      .then((res) => {
        console.log(res.data.data);
        notify(res.data.data, "success");
        // just reset
        // setData({
        //   productId: "",
        //   productName: "",
        //   tax: "",
        //   variantName: [],
        //   variantImage: [],
        //   tags: [[]],
        //   variantColor: [],
        //   actualPrice: [],
        //   discountPrice: [],
        // });
        // setVariantCount(1);
        setRender(!render);
      })
      .catch((err) => {
        console.log(err);
        notify(err, "danger");
      });
  }
  const [elements, setElements] = useState([]);

  const addElement = () => {
    setElements([...elements, 1]);
  };

  const removeElement = () => {
    if (elements.length > 0) {
      const updatedElements = [...elements];
      updatedElements.pop();

      setElements(updatedElements);
    }
  };
  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Product Variant</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Add Product Variant</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handSubmit}>
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">Product Name</Label>
                          <Select
                            className="select2"
                            options={productList}
                            styles={customStyles}
                            onChange={(e) => {
                              handleChange(e, undefined, "productName");
                            }}
                          />
                        </FormGroup>

                        {/* variant name */}
                        <FormGroup>
                          <Label htmlFor="exampleEmail7">Variant Name</Label>
                          <div className="row">
                            <div className="col-lg-6">
                              {" "}
                              <Input
                                type="text"
                                name="variantName"
                                id="exampleEmail7"
                                placeholder=""
                                onChange={(e) => {
                                  handleChange(e, undefined, "variantName");
                                }}
                                required
                              />
                            </div>
                          </div>
                        </FormGroup>

                        {/* tags */}
                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Tags{" "}
                            <span
                              style={{
                                color: "#f9c113",
                                fontWeight: "bold",
                              }}
                            >
                              (Keywords used for searching products in website)
                            </span>
                          </Label>
                          <div className="form-group">
                            <div className="controls">
                              <TagsInput
                                value={data.tags}
                                name="tags"
                                onChange={(e) => {
                                  handleChange(e, undefined, "tags");
                                }}
                                onlyUnique={true}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        {/* <FormGroup>
                          <Label htmlFor="exampleEmail7">Variant Name</Label>
                          <div className="row">
                            <div className="col-lg-6">
                              {" "}
                              <Input
                                type="text"
                                name="variantName"
                                id="exampleEmail7"
                                placeholder=""
                                onChange={handleChange}
                                required
                              />
                            </div>
                           
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Tags{" "}
                            <span
                              style={{ color: "#ffb74d", fontWeight: "bold" }}
                            >
                              (Keywords used for searching products in website)
                            </span>
                          </Label>
                          <div className="form-group">
                            <div className="controls">
                              <TagsInput
                                value={data.tags}
                                name="tags"
                                onChange={setTags}
                                onlyUnique={true}
                              />
                            </div>
                          </div>
                        </FormGroup> */}

                        {Array.from({ length: variantcount }, (x, i) => (
                          <div key={i}>
                            {i > 0 ? (
                              <div>
                                <br />
                                <hr style={{ borderColor: "#526db4" }} />
                                <br />
                              </div>
                            ) : (
                              ""
                            )}
                            {/* image */}
                            <FormGroup>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "10.5fr 1.5fr",
                                  columnGap: "20px",
                                }}
                              >
                                <div>
                                  <Label htmlFor="exampleFile">
                                    Variant Image
                                  </Label>
                                  <br />
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    {" "}
                                    Dimensions : 480 x 480
                                  </span>{" "}
                                  <br />
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    {" "}
                                    Size: Max 3mb
                                  </span>
                                  <br />
                                  <Cropper
                                    imageStore={(i, e) => {
                                      handleChange(e, i, "image");
                                    }}
                                    index={i}
                                    reset={render}
                                    aspectRatio={1 / 1}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    className="addIcon"
                                    onClick={() => addElement()}
                                  >
                                    +
                                  </span>
                                </div>
                              </div>
                            </FormGroup>
                            {elements.map((item, index) => {
                              return (
                                <FormGroup key={index}>
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "10.5fr 1.5fr",
                                      columnGap: "20px",
                                    }}
                                  >
                                    <div>
                                      <Label htmlFor="exampleFile">
                                        Variant Image
                                      </Label>
                                      <br />
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        Dimensions : 480 x 480
                                      </span>{" "}
                                      <br />
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        Size: Max 3mb
                                      </span>
                                      <br />
                                      <Cropper
                                        imageStore={(i, e) => {
                                          handleChange(e, i, "image");
                                        }}
                                        index={i}
                                        reset={render}
                                        aspectRatio={1 / 1}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        position: "relative",
                                        alignItems: "center",
                                        columnGap: "10px",
                                      }}
                                    >
                                      <span
                                        className="addIcon"
                                        onClick={() => addElement()}
                                      >
                                        +
                                      </span>
                                      <span
                                        className="removeIcon"
                                        onClick={() => removeElement()}
                                      >
                                        -
                                      </span>
                                    </div>
                                  </div>
                                </FormGroup>
                              );
                            })}
                            {/* color picker */}
                            {/* <FormGroup>
                              <Label htmlFor="exampleText">Color</Label>
                              <div
                                className="form-group"
                                style={{
                                  textAlign: "left",
                                }} */}

                            {/* <ColorPicker
                                  defaultColor="#000"
                                  animation="slide"
                                  onChange={(e) => handleArrayChange(e, i)}
                                /> */}
                            {/* 
                                <input
                                  name="variantColor"
                                  type="color"
                                  value={data.variantColor}
                                  onChange={(e) =>
                                    handleChange(e, undefined, "variantColor")
                                  }
                                />
                              </div>
                            </FormGroup> */}
                            {/* actual price */}
                            <FormGroup>
                              <Label htmlFor="exampleText">Actual Price</Label>
                              <Input
                                type="number"
                                name="actualPrice"
                                id="exampleEmail7"
                                placeholder=""
                                onChange={(e) =>
                                  handleChange(e, undefined, "actualPrice")
                                }
                                required
                              />
                            </FormGroup>
                            {/* discount price */}
                            <FormGroup>
                              <Label htmlFor="exampleText">
                                Discount Price
                              </Label>
                              <Input
                                type="number"
                                name="discountPrice"
                                id="exampleEmail7"
                                placeholder=""
                                onChange={(e) =>
                                  handleChange(e, undefined, "discountPrice")
                                }
                                required
                              />
                            </FormGroup>
                            {/* </div> */}
                          </div>
                        ))}

                        {/* add variant button */}
                        {/* <FormGroup>
                          <div
                            className="btn btn-primary"
                            onClick={() => {
                              data.tags.push([]);
                              setVariantCount(variantcount + 1);
                            }}
                          >
                            +
                          </div>
                          {variantcount > 1 ? (
                            <div
                              className="btn btn-primary"
                              onClick={() => {
                                data.tags.pop();
                                setVariantCount(variantcount - 1);
                              }}
                            >
                              -
                            </div>
                          ) : (
                            ""
                          )}
                        </FormGroup> */}

                        {/* <FormGroup>
                          <Label htmlFor="exampleEmail7">Available Stock</Label>
                          <Input
                            type="text"
                            name="availableStock"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                            required
                          />
                        </FormGroup> */}

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#f390bc",
                              color: "#fff",
                            }}
                          >
                            Submit
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* <ProductList /> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddVariant;
